/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import './playlist-v1.js';
import { elemHeight, elemWidth } from 'utilities/elem.js';
import { merge, getDeep, setDeep, castDeep } from 'utilities/obj.js';
import { parseUrl } from 'utilities/url.js';
import { doTimeout } from 'utilities/timeout-utils.js';
//
// this script assumes window.Wistia, window.playlistJson, and window.optionsJson
// have already been set on the page.
//
//
((
  Wistia, // NOTE: We are setting this to two variables because we should migrate
) =>
  // playlist iframes to use Wistia.playlistIframeInit. Right now, Wistia.iframeInit is
  // actually overwriting the function with the same name that's used for
  // initializing media data. This is just confusing.
  (Wistia.playlistIframeInit = Wistia.iframeInit =
    function (playlistJson, optionsJson) {
      // get the url params
      let iframeShim;
      const url = parseUrl(location.href);
      const embedOptions = merge(optionsJson, url.params);
      if (!embedOptions.videoOptions) {
        embedOptions.videoOptions = {};
      }

      // try to get the pageUrl from the referrer
      if (!embedOptions.videoOptions.pageUrl) {
        embedOptions.videoOptions.pageUrl =
          (document.referrer || '').replace(/#.*$/, '') || url.absolute().replace(/#.*$/, '');
      }
      embedOptions.videoOptions.pageTitle = document.title;

      // cast each option value appropriately
      const playerColor = `${getDeep(embedOptions, 'videoOptions.playerColor') || ''}`;
      const list = getDeep(embedOptions, 'plugin.requireEmail-v1.list');
      castDeep(embedOptions);
      if (playerColor) {
        embedOptions.videoOptions.playerColor = playerColor;
      }
      if (list != null) {
        setDeep(embedOptions, 'plugin.requireEmail-v1.list', list);
      }

      // set any options that need special logic (or zero logic)
      embedOptions.container = 'wistia_playlist';
      if (!embedOptions.videoOptions.canonicalUrl) {
        embedOptions.videoOptions.canonicalUrl = embedOptions.videoOptions.pageUrl;
      } else {
        embedOptions.videoOptions.pageUrl = embedOptions.videoOptions.canonicalUrl;
      }
      if (!embedOptions.videoOptions.canonicalTitle) {
        embedOptions.videoOptions.canonicalTitle = embedOptions.videoOptions.pageTitle;
      } else {
        embedOptions.videoOptions.pageTitle = embedOptions.videoOptions.canonicalTitle;
      }

      embedOptions._inIframe = true;

      // embed that playlist!
      window.wistiaPlaylist = Wistia.playlist(playlistJson, embedOptions);
      window.wistiaPlaylist._hashedId = url.path[2];
      if ((iframeShim = document.getElementById('iframe_shim'))) {
        iframeShim.contentWindow.postMessage('wistia-post-visitor-key', '*');
      }
      doTimeout(`${window.wistiaPlaylist.uuid}.fit_later`, () => window.wistiaPlaylist.fit(), 100);

      // Force a redraw every 3 seconds for iphones and ipads.
      // Without this, they don't always load offscreen.
      if (Wistia.detect.iphone || Wistia.detect.ipad) {
        window.wistiaPlaylist.ready(() =>
          setInterval(() => {
            window.wistiaPlaylist.currentVideo().elem().style.zoom = '';
            // eslint-disable-next-line no-unused-expressions
            window.wistiaPlaylist.currentVideo().elem.offsetTop;
            return (window.wistiaPlaylist.currentVideo().elem().style.zoom = 1);
          }, 3000),
        );
      }

      // Resize the playlist if the iframe window size changes
      // We can't use onresize because it doesn't fire when the
      // iframe is resized--only when the top frame is.
      let lastWidth = elemWidth(window);
      let lastHeight = elemHeight(window);
      window.wistiaPlaylist.ready(
        () =>
          (window.wistiaPlaylist._autoResizeIntervalId = setInterval(() => {
            const widthNow = elemWidth(window);
            const heightNow = elemHeight(window);
            if (lastWidth !== widthNow) {
              window.wistiaPlaylist.width(widthNow);
              lastWidth = widthNow;
            }
            if (lastHeight !== heightNow) {
              window.wistiaPlaylist.height(heightNow);
              lastHeight = heightNow;
            }
          }, 500)),
      );

      // ## Iframe API Support

      // Not set to start out. The iframe API will pass in this
      // value when we do the handshake.
      window.playlistApiSignature = null;

      // Send a signal to the parent that we should look for
      // new iframes on the page. this is the notification for the
      // Iframe API to find unbound iframes and do a handshake.
      parent.postMessage('new-wistia-iframe', '*');

      // Expect serialized JSON for IE8, object for everything else.
      const messageFromEvent = function (event) {
        if (typeof event.data === 'string') {
          try {
            return JSON.parse(event.data);
          } catch (e) {
            Wistia.notice(e);
            return {};
          }
        } else {
          return event.data;
        }
      };

      // Serialize JSON for IE8, no changes for everything else.
      const serializedMessage = (message) => JSON.stringify(message);

      const bindEvent = (elem, event, fn) => elem.addEventListener(event, fn, false);

      return (function () {
        // Send a JSON-encoded, signed message to the parent
        // of the iframe. Since message listening is handled
        // at the window level, the signature is used to
        // determine if the message should be parsed at all.
        window.wistiaPlaylist.postMessage = function (method, ...args) {
          if (parent === self) {
            return;
          }
          const message = {
            signature: window.playlistApiSignature,
            method,
            args,
          };
          return parent.postMessage(serializedMessage(message), '*');
        };

        // We have a few stateful properties that we might want to
        // query from the iframe API. We send updates for them when
        // they change.
        window.wistiaPlaylist.bind('down', () =>
          window.wistiaPlaylist.postMessage('updateProperties', { _down: true }),
        );
        window.wistiaPlaylist.bind('up', () =>
          window.wistiaPlaylist.postMessage('updateProperties', { _down: false }),
        );
        window.wistiaPlaylist.bind('volumechange', (v) =>
          window.wistiaPlaylist.postMessage('updateProperties', { _volume: v }),
        );
        window.wistiaPlaylist.bind('statechange', (s) =>
          window.wistiaPlaylist.postMessage('updateProperties', { _state: s }),
        );

        // Marshal all events up to the parent.
        window.wistiaPlaylist.bind('all', (...args) =>
          window.wistiaPlaylist.postMessage('trigger', ...Array.from(args)),
        );

        // Assume any message being passed to us is JSON formatted
        // and meant for us.
        const receiveMessage = function (event) {
          const message = messageFromEvent(event);

          // The iframe API has told us the signature it will
          // look for. Save that value so that we can message
          // the correct instance of the iframe API.
          if (message.method === 'remove') {
            window.wistiaPlaylist.remove();
          } else if (message.method === 'startIframeHandshake') {
            window.playlistApiSignature = message.args[0];

            // Send a message to the parent immediately so it
            // knows messaging is set up properly.
            window.wistiaPlaylist.postMessage('relay.trigger', 'iframebound');

            // When the video is ready, update wth more properties
            // that are only guaranteed available on ready.
            window.wistiaPlaylist.hasData(() => {
              window.wistiaPlaylist.postMessage('updateProperties', {
                _mediaData: __guard__(window.wistiaPlaylist.currentVideo(), (x) => x._mediaData),
              });
              return window.wistiaPlaylist.postMessage('hasData', true);
            });
            window.wistiaPlaylist.ready(() => {
              window.wistiaPlaylist.postMessage('updateProperties', {
                _visitorKey: window.wistiaPlaylist.getVisitorKey(),
                _eventKey: window.wistiaPlaylist.getEventKey(),
              });
              return window.wistiaPlaylist.postMessage('relay.trigger', 'videoready');
            });
          } else if (message.signature === window.playlistApiSignature) {
            // Execute the given method with the proper args
            if (
              message.method === 'play' &&
              (Wistia.detect.iphone || Wistia.detect.ipad || Wistia.detect.android)
            ) {
              // Ignore play messages passed via iframe API on mobile
            } else {
              window.wistiaPlaylist[message.method].apply(window.wistiaPlaylist, message.args);
            }
          }

          return true;
        };

        // Start listening for messages
        return bindEvent(window, 'message', receiveMessage, false);
      })();
    }))(window.Wistia);

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined;
}
